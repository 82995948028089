/** @jsx jsx */
// import React from "react"
import { Styled, jsx } from "theme-ui";
import { Link } from "gatsby";
// import PropTypes from "prop-types";
import Container from "./Container";

export default ({ titleName, titleSub, titleLink, titleLinkURL }) => (
  <Container>
    <Styled.h1
      sx={{
        maxWidth: (960 / 3) * 2,
        mb: 0
      }}
    >
      {titleName}
    </Styled.h1>

    <Styled.h3 sx={{ color: "primary", mt: 2 }}>{titleSub}</Styled.h3>

    <Styled.a as={Link} to={titleLinkURL}>
      {titleLink}
    </Styled.a>
  </Container>
);

// Title.propTypes = {
//   siteTitle: PropTypes.string,
// };

// Title.defaultProps = {
//   siteTitle: "",
// };

// export default Title;
